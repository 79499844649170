import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import React, { useEffect, useState } from "react";
import "../styles/page/customersLp.scss";
import CustomerCardSlider from "../components/customerLp/CustomerCardSlider";
import HeroSection from "../components/customerLp/HeroSection";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import CustomerStories from "../components/customerLp/CustomerStories";
import CustomerVerticalCard from "../components/customerLp/CustomerVerticalCard";
import DemoModal from "../components/DemoModal";
import CustomerCard from "../components/customerLp/CustomerCard";
import Explore from "../components/V3/Blog/Explore";
import { pickSlice, sanatizeSearchText } from "../utilities/helpers";
import { useMediaQuery } from "react-responsive";
import Pagination from "../components/Pagination";
import { CloseIcon, SearchIcon } from "../assets/icons";
import Filter from "../components/V3/Blog/Filter";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomCardSkeleton from "../components/customerLp/customerCardSkelton";

const LIMIT = 9;

const CustomerLp = (data, location) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stories, setStories] = useState(
    data?.data?.allPrismicBlog?.edges || []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTags, setActiveTags] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const [storiesToShow, setStoriesToShow] = useState(LIMIT);

  const _data = data?.data?.prismicCaseStudies?.data || {};

  const [customerStories] = pickSlice(_data, "customer_stories_avatar");
  const [encord_other_products] = pickSlice(_data, "encord_other_products");
  useEffect(() => {
    const storiesData =
      data?.data?.allPrismicBlog &&
      !!data?.data?.allPrismicBlog?.edges.length &&
      data?.data?.allPrismicBlog?.edges.filter(
        (_blog) =>
          _blog.node.uid !== data?.data?.prismicBlog?.uid &&
          _blog.node.tags.includes("Customers")
      );

    if (storiesData.length) {
      setStories(storiesData);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    const regex = new RegExp(sanatizeSearchText(searchText), "iu");

    const filteredStories =
      data?.data?.allPrismicBlog &&
      !!data?.data?.allPrismicBlog?.edges.length &&
      data?.data.allPrismicBlog.edges?.filter(
        (x) => x.node?.data?.title?.text?.match(regex)?.length
      );

    if (sortBy === "oldest") {
      filteredStories.sort((a, b) => {
        return (
          new Date(a.node.first_publication_date) -
          new Date(b.node.first_publication_date)
        );
      });
    } else if (sortBy === "a-z") {
      filteredStories.sort((a, b) => {
        const charA = a.node.data.title.text.charAt(0);
        const charB = b.node.data.title.text.charAt(0);

        if (isNaN(charA) && !isNaN(charB)) {
          return -1;
        } else if (!isNaN(charA) && isNaN(charB)) {
          return 1;
        } else {
          return a.node.data.title.text.localeCompare(b.node.data.title.text);
        }
      });
    } else if (sortBy === "z-a") {
      filteredStories.sort((a, b) =>
        b.node.data.title.text.localeCompare(a.node.data.title.text)
      );
    }

    setStories(filteredStories);
  }, [searchText, sortBy]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTags]);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const filteredStories =
    stories && stories.length
      ? stories.filter((blog) => {
          const storiesTags = blog.node.tags.map((tag) => tag.toLowerCase());
          const lowercaseTags = activeTags.map((tag) => tag.toLowerCase());

          return (
            lowercaseTags.length === 0 ||
            storiesTags.some((storiesTag) => lowercaseTags.includes(storiesTag))
          );
        })
      : [];

  const handleRemoveTag = (removedTag) => {
    if (removedTag === sortBy) {
      setSortBy("");
    } else {
      setActiveTags(activeTags.filter((tag) => tag !== removedTag));
    }
  };

  const allFilters = [...activeTags, sortBy].filter(Boolean);

  const handleFetchMore = () => {
    setTimeout(() => {
      setStoriesToShow((prev) => prev + LIMIT);
    }, 2000);
  };

  return (
    <Layout
      location={location}
      theme="transparent"
    >
      <article>
        <HeroSection data={data} location={location} />
        <section className="">
          <CustomerCardSlider data={data} location={location} />
        </section>
        <section className="relative max-w-6xl px-4 py-10 mx-auto sm:px-12 xl:px-0 ">
          <section className="pb-10 mx-auto ">
            <CustomerStories data={customerStories} />
          </section>
          <section className="pb-10">
            <Explore />
          </section>

          <section>
            {/* <Filter
              {...{
                setSearchText,
                searchText,
                tags: data?.data?.allPrismicBlog?.distinct.filter(
                  (tag) => tag !== "Customers"
                ),
                activeTags,
                setActiveTags,
                sortBy,
                setSortBy,
              }}
            /> */}
            {!!allFilters.length && (
              <section className="flex flex-wrap gap-4 mt-4">
                {allFilters.map((tag) => (
                  <div
                    className="flex items-center gap-1.5 bg-gray-1600 px-3 py-1.5 rounded-[20px] cursor-pointer"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    <CloseIcon />
                    <span className="text-base font-medium text-gray-600 uppercase tracking-widest_1">
                      {tag}
                    </span>
                  </div>
                ))}
              </section>
            )}
          </section>

          <InfiniteScroll
            dataLength={storiesToShow}
            next={handleFetchMore}
            hasMore={storiesToShow < filteredStories.length}
            loader={<CustomCardSkeleton length={3} />}
            scrollThreshold={0.4}
          >
            <section className="flex flex-col gap-6 mx-auto mt-5 sm:mt-6">
              {filteredStories.slice(0, storiesToShow).map((blog, index) => (
                <div key={`blog_card_${index}`}>
                  <CustomerCard
                    allowSubDetails={false}
                    searchText={searchText}
                    location={location}
                    key={`blog_card_${index}`}
                    blog={blog}
                    list={true}
                  />
                </div>
              ))}
            </section>
          </InfiniteScroll>

          <section className="py-5 sm:py-10 rounded-2xl sm:rounded-2.5xl">
            <CustomerVerticalCard
              data={encord_other_products}
              descriptionClass={`min-h-12`}
            />
          </section>
          <section>
            <AnnotateFooter
              heading={_data?.cta_heading?.text}
              description={_data?.cta_description?.text}
              openModal={handleModal}
              imageBG={true}
            />
          </section>
        </section>

        <DemoModal
          isOpen={isOpen}
          closeModal={handleModal}
          location={location}
        />
      </article>
    </Layout>
  );
};

export const query = graphql`
  {
    prismicCaseStudies {
      data {
        description {
          html
          text
        }
        page_heading {
          html
          text
        }
        page_label {
          html
          text
        }
        cta_description {
          text
        }
        cta_heading {
          text
          html
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        body {
          ... on PrismicCaseStudiesDataBodyEncordOtherProducts {
            id
            slice_type
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
              }
              card_label {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicCaseStudiesDataBodyCustomerStoriesAvatar {
            id
            items {
              avatar {
                url
                alt
              }
              company_logo {
                alt
                url
              }
              company_name {
                html
                text
              }
              description1 {
                html
                text
              }
              heading1 {
                html
                text
              }
              name {
                html
                text
              }
              designation {
                html
                text
              }
            }
            primary {
              section_heading {
                html
                text
              }
            }
            slice_type
          }
        }
      }
    }
    allPrismicBlog(
      sort: { first_publication_date: DESC }
      filter: {
        tags: { in: "Customers" }
      }
    ) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              html
              text
            }
            body {
              ... on PrismicBlogDataBodyTable {
                id
                primary {
                  table_title {
                    text
                  }
                }
                items {
                  col1 {
                    text
                    html
                  }
                  col2 {
                    text
                    html
                  }
                  col3 {
                    text
                    html
                  }
                  col4 {
                    text
                    html
                  }
                }
              }
            }
            image {
              url
              gatsbyImageData(
                width: 583
                height: 324
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
            sub_image {
              url
              gatsbyImageData(
                width: 80
                height: 42
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
            title {
              text
            }
            stats {
              text
            }
            read_time
          }
          first_publication_date
          tags
        }
      }
      distinct(field: { tags: SELECT })
    }
  }
`;

export const Head = () => {
  return (
    <>
      <SEO
        title="Computer Vision Customer Stories | Encord"
        description="Cutting edge start-ups and the world's biggest organizations use Encord's active learning platform to accelerate model development. Take a look at how they're doing it."
      />
    </>
  );
};

export default CustomerLp;
