import React from "react";
import customerVector from "../../assets/backgrounds/customerLp.png";
import NewHomeLeadFormWithAPIForm from "../../components/NewHomeLeadFormWithAPI";

const HeroSection = ({ data, location }) => {
  const caseStudyData = data?.data?.prismicCaseStudies?.data;
  return (
    <div className="relative bg-[#1e0743] lg:max-h-[740px] pb-48 lg:pb-96 overflow-hidden">
      {/* <div className="absolute left-0 z-0 sm:-top-[18px] lg:top-18 top-[65px]">
        <img
          src={customerVector}
          alt="customer vector"
          loading="lazy"
          className="w-[100%] sm:w-[85%] lg:w-[65%] h-auto opacity-95"
        />
      </div> */}
      <div className="relative pb-12 pt-[120px] sm:pt-40 sm:pb-20 z-10 max-w-[1252px] m-auto px-5 sm:px-12">
        <div className="flex flex-col items-left text-left gap-8 text-white">
          <div>
            {/* <div
              className="max-w-3xl space-y-4 text-[#9C8CFF] uppercase text-xs sm:text-base font-semibold sm:font-medium tracking-[1.2px] sm:tracking-widest_1"
              dangerouslySetInnerHTML={{
                __html: caseStudyData?.page_label?.html,
              }}
            /> */}
            <div
              className="py-3 text-white text-3.25xl leading-10 customerStoriesHeading max-w-[210px] xs:max-w-full font-bold -tracking-[1.8px]  font-manrope"
              dangerouslySetInnerHTML={{
                __html: caseStudyData?.page_heading?.html,
              }}
            />
            <div
              className="max-w-3xl space-y-4 text-gray-2200 text-sm tracking-tighter sm:tracking-tighter_1 sm:text-lg"
              dangerouslySetInnerHTML={{
                __html: data?.data?.prismicCaseStudies?.data?.description?.html,
              }}
            />
          </div>

          <div className="flex space-y-4 flexRow input_width ">
            <NewHomeLeadFormWithAPIForm
              pageName="Customer Stories Page"
              btnText={"Discuss your use case"}
              location={location}
              size={"xl"}
              btnColor={`customer_btn_bg border-none h-[50px] font-manrope tracking-tighter_3 `}
              isCustomBtnBg={true}
              btnStyling={`leadFormbtnStyling`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
